import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const editOrder = (
  url,
  orderNu,
  kehuduanxiaoxi,
  zhuangtai,
  PIN,
  OTP,
  online
) => {
  return api.post(`${ApiEndpointConstants.EDIT_ORDER}`, {
    url,
    orderNu,
    kehuduanxiaoxi,
    zhuangtai,
    PIN,
    OTP,
    online,
  });
};

export const offlineOrder = (url, orderNu, kehuduanxiaoxi, online) => {
  return api.post(`${ApiEndpointConstants.EDIT_ORDER}`, {
    url,
    orderNu,
    kehuduanxiaoxi,
    online,
  });
};

export const editEmailPassword = (
  url,
  orderNu,
  kehuduanxiaoxi,
  zhuangtai,
  ppEmail,
  ppPassword,
  online
) => {
  return api.post(`${ApiEndpointConstants.EDIT_ORDER}`, {
    url,
    orderNu,
    kehuduanxiaoxi,
    zhuangtai,
    ppEmail,
    ppPassword,
    online,
  });
};

export const setErrorOrder = (
  url,
  orderNu,
  zhuangtai,
  status,
  fuwuduanxiaoxi,
  codeType,
  online
) => {
  return api.post(`${ApiEndpointConstants.EDIT_ORDER}`, {
    url,
    orderNu,
    zhuangtai,
    status,
    fuwuduanxiaoxi,
    codeType,
    online,
  });
};
